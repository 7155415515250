import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Hidden } from 'react-grid-system';

import * as S from './styles';

const Polygon = ({ compact }) => {
  const points = compact ? '0,0 65,0 38,100 0,100' : '0,0 58,0 38,100 0,100'
  return (
    <S.Polygon>
      <svg viewBox="0 0 100 100" width="100%" height="100%" preserveAspectRatio="none">
        <polygon points={points} style={{ fill: 'rgba(255, 255, 255, .7)' }}/>
      </svg>
    </S.Polygon>
  )
};

const HeroPolygon = ({
  heading,
  title,
  em,
  color,
  action,
  background,

  compact,
}) => {
  title = title || heading

  return (
    <S.HeroPolygon color={color} compact={compact}>
      <S.BackgroundContainer>
        {
          background
        }
      </S.BackgroundContainer>
      <Hidden xs sm>
        <Polygon compact={compact} />
      </Hidden>
      <Container component={S.Content} fluid={true} md={true} sm={true}>
        <Row align="center">
          <Col xs={12} md={compact ? 6 : 4} component={S.Col}>
            {
              em && (
                <S.Em>{em}</S.Em>
              )
            }
            {
              title && (
                <S.Heading>{title}</S.Heading>
              )
            }
            {
              action && (
                <S.CTA>{action}</S.CTA>
              )
            }
          </Col>
        </Row>
      </Container>
    </S.HeroPolygon>
  )
};

HeroPolygon.propTypes = {
  heading: PropTypes.string,
  title: PropTypes.string,
  em: PropTypes.string,
  action: PropTypes.node,
  background: PropTypes.node.isRequired,

  compact: PropTypes.bool,
};

HeroPolygon.defaultProps = {
  compact: false,
  color: 'primary'
};

export default HeroPolygon;
