import styled from 'styled-components';

import { breakpoint } from '../../ThemeProvider';

const ColorMap = {
  secondaryAlpha: 'secondaryBeta',
  tertiaryAlpha: 'tertiaryBeta',
  secondaryBeta: 'secondaryAlpha',
  tertiaryBeta: 'tertiaryAlpha',
};

export const Polygon = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

export const Heading = styled.span`
  margin: 0;
  padding: 0;
  line-height: 1.1;
  font-weight: bold;
  display: block;
`;

export const Em = styled(Heading)``;

export const CTA = styled.div`
  margin-top: 32px;
`;

export const HeroPolygon = styled.header`
  color: ${(props) => props.theme.palette[props.color]};
  position: relative;
  height: ${(props) => props.compact ? '180px' : 'auto'};
  display: flex;
  align-items: flex-end;

  ${Heading} {
    font-size: 3rem;
  }

  ${breakpoint.md`
    align-items: center;

    ${Heading} {
      font-size: ${(props) => props.compact ? '1.7rem' : '3.8rem'};
    }
  `}

  ${Em} {
    color: ${(props) => props.theme.palette[ColorMap[props.color]]};
  }

  min-height: ${(props) => props.compact ? 'auto' : '300px'};
  @media (min-width: 768px) {
    min-height: ${(props) => props.compact ? 'auto' : '600px'};
  }
`;

export const BackgroundContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  
  // image wrap elem
  > span {
    height: 100%;
  }

  img:not([data-lqip]) {
    width: 100%;
    min-height: 100%;
  }
`;

export const Col = styled.div`
  background: rgba(255, 255, 255, .7);
  padding-top: ${(props) => props.theme.utils.grid.gutterWidth / 2}px;
  padding-bottom: ${(props) => props.theme.utils.grid.gutterWidth / 2}px;
  text-align: center;
  
  ${breakpoint.md`
    background: transparent;
    padding-top: 0;
    padding-bottom: 0;
    text-align: left;
  `}
`;

export const Content = styled.div`
  
  ${breakpoint.md`
    padding: ${(props) => props.compact ? '18px 0' : '56px 0'};
    flex: 1;  
  `}
  
`;
